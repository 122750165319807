<template>
  <CRow>
    <CCol col="12" lg="8">
      <CToaster :autohide="2000">
        <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>      
      <template>           
        <div v-if="showInfoToast">
          <CToast          
          :show.sync="showInfoToast"
          color="info"
          >
          {{ infoToastMessage  }}            
          </CToast>
        </div>   
      </template>         
      </CToaster>

      <CCard style="max-width: 40rem;">
        <CCardHeader>
          Create Customer
        </CCardHeader>
        <CCardBody>
          <CCardBody class="p-4">
            <CForm>
              <CInput
                  label="Name"
                  placeholder="Customer Name"
                  v-model="customer.customer_name"
                  valid-feedback="Customer name is valid."
                  invalid-feedback="Minimum length is 4 and only characters and spaces."
                  description="The Name can be changed afterwards."
                  :is-valid="!$v.customer.customer_name.$invalid"  
                  :disabled="inputDisabled"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CInput
                  label="Email"
                  placeholder="Customer Email"
                  v-model="customer.customer_email"
                  valid-feedback="Customer email is valid."
                  invalid-feedback="Email is not a valid email format."
                  description="The Email can't be changed afterwards."
                  :is-valid="!$v.customer.customer_email.$invalid"  
                  :disabled="inputDisabled"
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>

                <CTextarea
                  label="Description"
                  v-model="customer.customer_desc"
                  :value.sync="customer.customer_desc"       
                  :is-valid="!$v.customer.customer_desc.$invalid"  
                  invalid-feedback="Description is not valid."
                  :disabled="inputDisabled"
                  />                                

            </CForm>
          </CCardBody>
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>
          <CButton 
            v-on:click="openCustomer();" 
            color="success" 
            class="float-right col-sm-4" 
            v-show="showOpenCustomer" 
          >
            Open Customer ({{ timeLeftToForward }}s)
          </CButton>          
          <CButton
            v-on:click="createCustomer"
            color="success"
            class="float-right col-sm-4"
            v-show="!showOpenCustomer"
            :disabled="$v.$invalid || inputDisabled"
          >
            Create Customer
          </CButton> 
          
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import CustomerService from '@/services/customer.services';
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9- _\\.&!#=()\\s])*$/i);
const customerDescValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9- _\,.&!#=\\\/()\s]){0,}$/i);

export default {
  data() {
    return {
      customer: {
        customer_name: '',
        customer_desc: '',
        customer_email: '',
      },
      showOpenCustomerId: '',
      showOpenCustomer: false,
      inputDisabled: false,
      showSuccessToast: false,
      showErrorToast: false,
      showInfoToast: false,
      successToastMessage: "",
      errorToastMessage: "",
      infoToastMessage: "",      
      timerInterval: null,
      timePassedToForward: 0,
      timeToForward: 5,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    customer: {
      customer_name: {
        minLength: minLength(4),
        maxLength: maxLength(32),
        required,
        alphaNumAndDotValidator,
        $autoDirty: true
      },
      customer_email: {
        required,
        email
      },
      customer_desc: {
        minLength: minLength(0),
        maxLength: maxLength(2048),        
        customerDescValidator,
        $autoDirty: true
      }
    }
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassedToForward += 1), 1000);
    },    
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.openCustomer();
    },    
    goBack() {
      this.$router.push({ path: '/customers' });
    },
    openCustomer() {
      this.$router.push({path: '/customers/'+this.showOpenCustomerId})
    },      
    async createCustomer() {
      this.inputDisabled = true;

      try {
        const response = await CustomerService.createCustomer(
          this.customer.customer_name,
          this.customer.customer_email,
          this.customer.customer_desc,
          this.$cognitoAuth.getCurrentUser().username
        );
        await this.createCustomerCredentials(response.customer_id);
        this.inputDisabled = true;
        this.successToastMessage = "Customer created successfully. Opens in 5 seconds.";
        this.showSuccessToast = true;

        this.showOpenCustomer = true;
        this.showOpenCustomerId = response.customer_id;
        this.startTimer();

      } catch (error) {
        this.inputDisabled = false;
        console.error(error);
        this.errorToastMessage = "Error creating Customer. Please try again later.";
        this.showErrorToast = true;
      }
    },
    async createCustomerCredentials(id) {
      try {
        await CustomerService.createCustomerCredentials(id);
      } catch (error) {
        console.error(error);
        this.errorToastMessage = "Error creating Customer Credentials. Please try again later.";
        this.showErrorToast = true;
      }
    },    
  },
  computed: {
    timeLeftToForward() {
      return this.timeToForward - this.timePassedToForward;
    }
  },
  watch: {
    timePassedToForward(newValue) {
      if (newValue === this.timeToForward) {
        this.onTimesUp();
      }
    }
  },  
};
</script>
